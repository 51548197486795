import React from 'react';
import styled from 'styled-components';

const StyledFeature = styled.div`
    display: flex;
    flex-direction: column;
`;

const Icon = styled.img`
    max-width: 7.5rem;
    margin-bottom: 2.4rem;
`;

const Description = styled.p`
    color: var(--color-body-dark);
`;

const Feature = ({ icon, title, children }) => {
    return (
        <StyledFeature>
            <Icon src={icon} />
            <h3>{title}</h3>
            <Description>{children}</Description>
        </StyledFeature>
    )
}

export default Feature;