import React from 'react'
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledLinkButton = styled.div`
  margin: ${props => props.margin};
  a {
    display: inline-flex;
    align-items: center;
    height: 6rem;
    padding: 0 4rem;
    font-size: 2.2rem;
    font-weight: 700;
    text-align: center;
    color: ${props => props.textColor};
    background: ${props => props.backgroundColor};
    border-radius: 0.6rem;
    cursor: pointer;
    transition: box-shadow 0.15s ease-in-out;
    :hover {
      box-shadow: var(--shadow-element);
    }
  }
`;

const LinkButton = ({ type, children, href='', margin='0', backgroundColor='var(--color-yellow)', textColor='#000000' }) => {
    let link = null;
    switch (type) {
        default:
        case 'external':
            link = <a href={href}>{children}</a>
            break;
        case 'internal':
            link = <Link to={href}>{children}</Link>
            break;
    }
    
    return (
        <StyledLinkButton margin={margin} backgroundColor={backgroundColor} textColor={textColor}>
            {link}
        </StyledLinkButton>
    )
}

export default LinkButton;
